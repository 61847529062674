<template>
  <swiper-container
    class="mySwiper2"
    thumbs-swiper=".my-thumbs"
    :slides-per-view="1"
    :space-between="10"
    :thumbs="{ swiper: thumbsSwiper }"
    :modules="modules"
    :autoplay="{ delay: autoPlayTimer, pauseOnMouseEnter: true }"
    :breakpoints="{
      768: {
        slidesPerView: 1,
      },
    }"
    :watchOverflow="true"
    :watchSlidesProgress="true"
    :watchSlidesVisibility="true"
    @swiperautoplaytimeleft="onAutoplayTimeLeft"
  >
    <swiper-slide>
      <img src="/images/slider-1.jpg" alt="" srcset=""/>
    </swiper-slide>

    <swiper-slide>
      <img src="/images/slider-2.jpg" alt="" srcset=""/>
    </swiper-slide>

    <swiper-slide>
      <img src="/images/slider-3.jpg" alt="" srcset=""/>
    </swiper-slide>
  
  </swiper-container>

  <swiper-container
    class="my-thumbs"
    @swiper="setThumbsSwiper"
    :space-between="-300"
    :slidesPerView="11"
    :centered-slides="true"
    :freeMode="true"
    :watchSlidesProgress="true"
    :centeredSlidesBounds="true"
    :modules="modules"
  >
    <swiper-slide>
      <div class="slide-item">
        <img src="/images/download.jpg" alt="" srcset="" />
        <div class="swipper-progress-bar-background" ref="progressCircle">
          <div class="swipper-progress-bar" ref="progressContent"></div>
        </div>
      </div>
    </swiper-slide>

    <swiper-slide>
      <div class="slide-item">
        <img src="/images/thumbs-2.jpg" alt="" srcset="" />
        <div class="swipper-progress-bar-background">
          <div class="swipper-progress-bar"></div>
        </div>
      </div>
    </swiper-slide>

    <swiper-slide>
      <div class="slide-item">
        <img src="/images/thumbs-3.jpg" alt="" srcset="" />
        <div class="swipper-progress-bar-background" ref="progressCircle">
          <div class="swipper-progress-bar" ref="progressContent"></div>
        </div>
      </div>
    </swiper-slide>

  </swiper-container>
</template>

<script>
import { register } from "swiper/element/bundle";
import { ref } from "vue";

register();

export default {
  setup() {
    const thumbsSwiper = ref(null);
    const spaceBetween = 10;
    const autoPlayTimer = 5000;

    const setThumbsSwiper = (swiper) => {
      thumbsSwiper.value = swiper;
    };

    const onAutoplayTimeLeft = (e) => {
      const [swiper, time, progress] = e.detail;
      const bars = document.querySelectorAll(".swipper-progress-bar");
      const progressBars = document.querySelectorAll(
        ".swiper-slide-thumb-active  .swipper-progress-bar"
      );

      bars.forEach((bars) => {
        // reseta o valor

        bars.style.width = 0 + "%";
      });
      progressBars.forEach((progressBar) => {
        // Define a largura da barra com base no progresso

        progressBar.style.width = (100 - progress * 100) + "%";
      });
    };

    return {
      autoPlayTimer,
      thumbsSwiper,
      setThumbsSwiper,
      onAutoplayTimeLeft,
    };
  },
};
</script>



<style scoped>
.my-thumbs {
  margin-top: -55px;
  position: relative;
  height: 20%;
  padding: 10px 0;
  width: 100%;
}

.my-thumbs::part(wrapper) {
  padding: 16px 48px 0;
  width: fit-content;
  position: relative;
  z-index: 1;
}

.my-thumbs::part(wrapper)::before {
  background: #fff;
  border-radius: 6px;
  -webkit-clip-path: polygon(8% 0, 92% 0, 100% 100%, 0 100%);
  clip-path: polygon(8% 0, 92% 0, 100% 100%, 0 100%);
  content: "";
  height: 100%;
  left: 49%;
  position: absolute;
  top: 0;
  transform: translateX(-50%);
  width: 112%;
}

.my-thumbs swiper-slide {
  position: relative;
}

.my-thumbs swiper-slide img {
  border-radius: 6px;
}

.slide-item {
  position: relative;
  width: fit-content;
  display: flex;
  flex-direction: column;
  row-gap: 5px;
}

.swipper-progress-bar-background {
  background: #c7c7c7;
  border-radius: 6px;
  height: 4px;
  width: 100%;
}

.swipper-progress-bar {
  --progress: 0;
  background: #373737;
  width: (--progress);
  height: 100%;
  border-radius: 6px;
}
</style>