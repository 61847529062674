<template>
    <header id="header">
        <div class="header__container">
            <div class="header-top">
                <div class="container-inner__left">
                    <router-link class="router-link" to="/">
                        <img src="images/logo (1).png" alt="">
                    </router-link>
                </div>
                <div class="container-inner__right">
                    <div class="account">
                        <a class="connect-link" href="">
                            <span>Conectar-se</span>
                            <font-awesome-icon icon="fa-regular fa-user" />
                        </a>
                    </div>
                    <div class="locale"></div>
                </div>
            </div>
            <div class="header-bottom">
                <div class="left">
                    
                </div>
                <div class="right">
                    <a href="" class="link-btn">
                        LesGuardians
                        <font-awesome-icon icon="fa-solid fa-arrow-up-right-from-square " class="link-btn__icon" />
                    </a>
                    <a href="" class="link-btn">
                        Launcher
                        <font-awesome-icon icon="fa-solid fa-download"  class="link-btn__icon" />
                    </a>
                </div>
            </div>
        </div>
    </header>
</template>


<style scoped>

@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

    .header-top {
        background: #E7E7E7;
        display: flex;
        justify-content: space-between;
        padding: 12px;
        align-items: center;
    }

    .container-inner__left img {
        max-height: 40px;
        opacity: .7;
    }


    .connect-link:hover {
        background: white;
        border-radius: 25px;
    }

    .connect-link {
        text-decoration: none;
        display: flex;
        column-gap: 5px;
        align-items: center;
        justify-content: end;
        font-size: 1rem;
        color: #272624;
        font-family: 'Roboto', sans-serif;
        padding: 3px 2px 3px 20px;
    }

    .connect-link .fa-user {
        padding: 8px;
        background: white;
        border-radius: 50%;
        font-size: 1.4rem;
    }
     .connect-link:hover .fa-user {
        background: #E7E7E7;
     }


     .header-bottom {
        align-items: center;
        background: linear-gradient(90deg,#f8f8f8 0,hsla(0,0%,100%,0)),url('https://store.ankama.com/build/images/bg-menu.9af50f5b.png');
        background-position: 100% 0;
        background-repeat: no-repeat;
        display: flex;
        justify-content: space-between;
        height: 68px;
        padding: 12px;
     }

      .header-bottom .right {
        display: flex;
        column-gap: 0.8rem;
      }

      .header-bottom .right .link-btn {
        text-decoration: none;
        padding: 10px 40px;
        background: white;
        border-radius: 35px;
        color: #272624;
        font-family: 'Roboto', sans-serif;
      }

      .header-bottom .right .link-btn  .link-btn__icon {
        font-size: 1.3rem;
        padding-left: 10px;
      }

       .header-bottom .right .link-btn:hover {
        background: #272624;
        color: white;
       }

    
</style>