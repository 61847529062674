<template>
  <Navbar/>
  <Banner/>
  <StoreDisplay/>
</template>

<script>
// @ is an alias to /src

import Navbar from '@/components/store/Navbar.vue'
import Banner from '@/components/store/BannerCarousel.vue'
import StoreDisplay from '@/components/store/StoreDisplay.vue'

export default {
  name: 'StoreView',
  components: {
   Navbar,
   Banner,
   StoreDisplay,

  }
}
</script>
