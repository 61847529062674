import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import '@/assets/css/global.css'



// FontAwesome

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
//import { faUser } from '@fortawesome/free-solid-svg-icons'
import { faUser} from '@fortawesome/free-regular-svg-icons'
import { faArrowUpRightFromSquare, faDownload } from '@fortawesome/free-solid-svg-icons'

/* add icons to the library */
library.add(faUser)
library.add(faArrowUpRightFromSquare, faDownload)


// End FontAwesome



createApp(App).component('font-awesome-icon', FontAwesomeIcon).use(router).mount('#app')
