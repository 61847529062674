><template>
  <div class="cards-container">
    <div class="items-cards">
      <router-link to="/" class="card-item" v-for="item in getCurrentPageItems()" :key="item">
        <div class="card-item__top">
          <div
            class="flag"
            :class="{ 'flag-favourite': item.isFav, 'flag-new': item.isNew }"
          >
            <span v-if="item.isFav">Favorito</span>
            <span v-if="item.isNew">Novidade</span>
          </div>
          <div class="image">
            <img :src="item.image" alt="" />
          </div>
        </div>
        <div class="card-item__infos">
          <img src="/images/retro-icon.svg" alt="" />
          <span>{{ item.name }}</span>
        </div>
        <div class="card-item__bottom"></div>
      </router-link>
    </div>
  </div>
  <div class="pagination-container">
    <v-pagination
      v-model="page"
      :pages="totalPages"
      :range-size="1"
      active-color="#DCEDFF"
      :container-class="'pagination'"
      :page-class="'page-item'"
    />
  </div>
</template>


<script>
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";

export default {
  data() {
    return {
      cardHover: false,
      items: [
        {
          isFav: false,
          isNew: false,
          name: "500 Ogrines",
          image: "/images/og/5.png",
        },
        {
          isFav: false,
          isNew: false,
          name: "1000 Ogrines",
          image: "/images/og/10.png",
        },
        {
          isFav: false,
          isNew: false,
          name: "2000 Ogrines",
          image: "/images/og/20.png",
        },
        {
          isFav: true,
          isNew: false,
          name: "3300 Ogrines",
          image: "/images/og/30.png",
        },
        {
          isFav: false,
          isNew: false,
          name: "5600 Ogrines",
          image: "/images/og/50.png",
        },
        {
          isFav: false,
          isNew: false,
          name: "11500 Ogrines",
          image: "/images/og/100.png",
        },
        {
          isFav: false,
          isNew: false,
          name: "23600 Ogrines",
          image: "/images/og/200.png",
        },
      ],
      page: 1,
      itemsPerPage: 20, // Número de itens por página
    };
  },
  components: {
    VPagination,
  },
  computed: {
    totalPages() {
      return Math.ceil(this.items.length / this.itemsPerPage);
    },
  },

  methods: {
    // Função para obter os itens a serem exibidos na página atual
    getCurrentPageItems() {
      const startIndex = (this.page - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      return this.items.slice(startIndex, endIndex);
    },
  },
};
</script>

<style>
.cards-container {
  display: flex;
  justify-content: center;
}

.items-cards {
  width: 80%;
  display: flex;
  flex-wrap: wrap;
  gap: 1.2rem;
}
.card-item {
  width: 20%;
  background: #eaeaea;
  position: relative;
  top: 0;
  transition: top ease-in-out 0.3s;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
}

.card-item .image img {
  border-radius: 20px 20px 0 0;
  width: 100%;
}

.card-item:hover {
  top: -10px;
}

.card-item .flag {
  -webkit-clip-path: polygon(100% 0, 0 0, 0 100%);
  clip-path: polygon(100% 0, 0 0, 0 100%);
  width: 80%;
  height: 80px;
  position: absolute;
  background-blend-mode: multiply;
  background: url(~@/assets/images/texture.webp) 0 0 no-repeat,
    var(--flag-bg-color);
  border-top-left-radius: 20px;
  display: flex;
}

.card-item .flag span {
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  align-self: flex-end;
  bottom: 20px;
  color: #fff;
  display: block;
  display: -webkit-box;
  font-size: 0.75rem;
  left: 12px;
  line-height: 1.5rem;
  max-width: 160px;
  position: relative;
  text-align: left;
  text-transform: uppercase;
  transform: rotate(-19deg) skew(-17deg, 0deg);
  font-family: "Roboto", sans-serif;
  font-weight: 700;
}

.flag-new {
  --flag-bg-color: linear-gradient(45deg, #e57c01, #f49509 56%, #f7d126);
}

.flag-favourite {
  --flag-bg-color: #df0a6e;
}

.card-item__infos {
  background: white;
  display: flex;
  align-items: center;
  column-gap: 10px;
  padding: 0 5px;
  font-size: 1.25rem;
  color: #121212;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  text-transform: capitalize;
}

.card-item__infos img {
  max-width: 32px;
}

.card-item__bottom {
  background-color: #373737;
  border-radius: 0 0 20px 20px;
  padding: 16px 0;
}

.card-item:hover .card-item__bottom {
  background-color: #000000;
}



.pagination-container {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}

.Pagination {
  display: flex;
  column-gap: 4px;
  flex-flow: row;
  flex-wrap: wrap;
  align-items: center;
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.PaginationControl {
  display: flex;
  align-items: center;
}

.Control {
  position: relative;
  display: block;
  width: 18px;
  height: 18px;
  margin: 0 2px;
  fill: #BBBBBB;
}

.Control-active {
  fill: #333333;
  cursor: pointer;
  transition: fill 0.2s ease-in-out;
}

.Control-active:hover {
  fill: #000000;
  transition: fill 0.2s ease-in-out;
}

.Page {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 42px;
  height: 42px;
  margin: 0 2px;
  color: #000000;
  font-size: 14px;
  border-radius: 50%;
  box-sizing: border-box;
  border: 0.5px solid rgba(128, 128, 128, 0.788);
  cursor: pointer;
  outline: 0;
  user-select: none;
}


.Page:hover {
    background-color: #e2e0dd91!important;
}

.Page-active {
  border: 3px solid #0d6efd;
  background-color: #272624!important;
  color: white;
}

.DotsHolder {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 22px;
  height: 22px;
  margin: 0 2px;
  box-sizing: border-box;
}

.Dots {
  width: 8px;
  height: 4px;
  fill: #BBBBBB;
}
</style>